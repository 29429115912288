@import '../../assets/styles/variables';

.formRoot {
  margin-right: auto;
  margin-left: auto;
  justify-content: space-evenly;
}

.formLabel {
  margin: 24px auto;
  font-family: Roboto-Medium, Arial, sans-serif;
  line-height: 25px;
  font-size: 24px;
  color: rgba(72, 92, 110, 1);
}

.formContainer {
  width: 965px;
  font-family: Roboto-Light, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $colorPaper;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  padding: 51px 98px;
  margin-bottom: 30px;
  border-radius: 14px;

  .vectorOne {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 14px;
  }
  .vectorTwo {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 14px;
  }
  .vectorThree {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 14px;
  }

  .closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    // background-color: $backgroundButton2;

    &:hover {
      background-color: $secondaryMainColor;
    }
  }

  .inputSection {
    align-self: center;

    .fieldLabel {
      font-family: 'Roboto-Medium', Arial, sans-serif;
      font-size: 16px;
      margin-bottom: 7px;
      margin-left: 4px;
      margin-top: 20px;
      color: rgba(72, 92, 110, 1);
    }

    .addSkillBlock {
      display: flex;
      align-items: flex-end;

      .addButton {
        width: 121px;
        height: 32px;
        border-radius: 7px;
        background-color: rgba(0, 121, 155, 1);
        color: $colorPaper;

        &:hover {
          background-color: rgba(0, 121, 155, 1);
          color: $colorPaper;
        }
      }
    }

    .buttonsBlock {
      display: flex;
      flex-wrap: wrap;
      // width: 100%;
      margin-top: 8px;
      padding: 8px;
      margin-bottom: 20px;
    }

    .submitBlock {
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 7px;
      width: 100%;
      margin-top: 50px;
      & > * {
        width: 201px;
        height: 36px;
        border-radius: 7px;
      }

      .submitButton {
        background-color: rgba(0, 121, 155, 1);
        color: $colorPaper;

        &:hover {
          background-color: rgba(0, 121, 155, 1);
          color: $colorPaper;
        }
      }
      .cancelButton {
        background-color: transparent;
        border: 1px solid rgba(0, 121, 155, 1);
        color: rgba(0, 121, 155, 1);
        &:hover {
          background-color: transparent;
          color: rgba(0, 121, 155, 1);
        }
      }

      .deleteButton {
        background-color: $backgroundButton3;

        &:hover {
          background-color: $backgroundButtonHover3;
        }

        &:active {
          background-color: $backgroundButtonActive3;
        }
      }
    }
  }

  .error {
    font-size: 0.75rem;
    color: red;
  }
}

@media (max-width: 749px) {
  .formRoot {
    padding: -10px;
    margin: 0;
    border-bottom: 30px solid rgba(239, 239, 239, 1);
  }

  .formLabel {
    margin: 16px auto;
    font-family: Roboto-Medium, Arial, sans-serif;
    line-height: 30px;
    font-size: 18px;
    font-weight: 700;
    padding: 0 16px;
  }

  .formContainer {
    padding: 1rem;

    border-radius: 0;
    width: 100%;

    .inputSection {
      margin-top: 0.5rem;

      .searchBlock {
        margin-top: unset;
      }

      .buttonsBlock {
        width: 100%;
        padding: 0.5rem;
        margin-top: 0;
        margin-bottom: 1rem;

        & > * {
          width: 100%;
          margin-right: unset;
        }
      }
      .submitBlock {
        display: flex;
        flex-wrap: wrap;
        gap: 7px;
        margin-top: 0;

        .submitButton {
          width: 100%;
        }

        .cancelButton {
          flex-grow: 1;
        }

        .deleteButton {
          flex-grow: 1;
        }
      }
    }
  }
}
