@import '../../../assets/styles/variables';

.content {
  width: 100%;
  // background: linear-gradient(to bottom, transparent, $inputBackgroundColor), url(../../../assets/images/headerPlaceholder.png) no-repeat;
  // background-size: auto 720px;
  // background-position-y: 70px;
  // background-position-x: center;
}

.contentWithSidebar {
  margin-left: 1rem;
  width: calc(100% - 298px);
}
@media (max-width: 959px) {
  .contentWithSidebar {
    margin-left: 0;
    width: 100%;
  }
}

.publicContent {
  width: 100%;
}

.publicContentWithSidebar {
  margin-left: 1rem;
  width: calc(100% - 298px);
}
@media (max-width: 959px) {
  .publicContentWithSidebar {
    margin-left: 0;
    width: 100%;
  }
}
