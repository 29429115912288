.container {
  z-index: 1000;
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  //   background-color: #f0f0f0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  @media (max-width: 959px) {
    top: 60px;
  }

  .title {
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
    max-width: 260px;
  }

  .LanguagesPopup {
    height: 100%;
    width: 802px;
    max-width: 802px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
    -webkit-box-align: center;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: 230px;
    margin-left: 0;

    @media (max-width: 959px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0 !important;
    }
  }

  .IconButton {
    margin: 0;
    padding: 12;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .LanguagesContainer {
    width: 536px;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0px;
    margin: 0;

    @media (max-width: 959px) {
      width: 100%;
    }
  }

  .input {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    padding: 0px;
    margin: 0px;
    border: 0px;
    vertical-align: top;
    width: 100%;
  }

  .searchField {
    border-radius: 33px;
    border: 0px;
    height: 40px;
    width: 394px;
    background-color: rgb(224, 239, 243);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: #1b2024;
    box-sizing: border-box;

    @media (max-width: 959px) {
      width: 100%;
    }
  }

  .searchFieldLabel {
    height: 40px;
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
    font-family: Roboto-Medium;
  }

  .languagesList {
    width: 350px;
    padding: 0px 16px;
    margin-left: 0px;
    height: 75%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: darkgrey rgb(241, 241, 241);
    
    @media (max-width: 959px) {
    width: 100%;
    padding: 0px;
    }
  }
  

  .option {
    cursor: pointer;
    display: flex;
    outline: 0;
    box-sizing: border-box;
    min-height: 48px;
    align-items: center;
    padding-top: 6px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 6px;
    justify-content: flex-start;
    -webkit-tap-highlight-color: transparent;

    font-size: 1rem;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  .Typography {
    font-size: 20px;
    font-weight:400;
    letter-spacing: 0.86px;
    line-height: 30px;
    text-align: center;
    color: #485C6E;
    white-space: nowrap;
  }

  .CircleFlag {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EEE;
    color: #333;
    font-size: 16px;
    font-size: 'Roboto', arial, sans-serif;
  }

  .LanguageName {
    margin: 0px;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: rgb(72, 92, 110);
    padding: 10px 16px;
    border-radius: 3px;
  }
}
