@import '../../assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem auto 0;
}

.content {
  padding: 3rem;
  max-width: 48rem;
  background-color: #fff;

  h1 {
    scroll-margin-top: 4rem;
    margin-top: 0;
    line-height: 1.39;
    margin-top: 3rem;

    &:first-child {
      margin-top: 0;
      scroll-margin-top: 4rem;
    }
  }
  p {
    line-height: 1.92;
  }
}
