@import '../../../assets/styles/variables';

/* width */
::-webkit-scrollbar {
  width: 12px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f0f0f0;
  margin-top: 4.5vw;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  margin-top: 4.5vw;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.main {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  cursor: default;
  background-color: $inputBackgroundColor;

  overflow-y: auto;
  height: 100vh;
}

.mainLogin {
  background-color: $backgroundColorHeader;
  justify-content: center;
}

.content {
  display: flex;
  width: 100%;

  @media (max-width: 959px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.contentWithBackgroundImage {
  background: linear-gradient(to bottom, transparent, $inputBackgroundColor),
    url(../../../assets/images/headerPlaceholder.png) no-repeat;
  background-size: auto 720px;
  background-position-y: 70px;
  background-position-x: center;
}

.contentWithNavigation {
  width: calc(100% - 272px);
}
@media (max-width: 959px) {
  .contentWithNavigation {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
