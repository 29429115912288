@import '../../assets/styles/variables';

.listItem {
  font-family: Roboto-Light, Arial, sans-serif;
  font-size: 0.9rem;
}

.selectArrow {
  font-size: 1rem;
  color: $borderColor;
}
