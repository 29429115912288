// @import '../../../assets/styles/variables';
@import '../../assets/styles/variables.scss';
.ratings {
  display: flex;
  flex-direction: column;
  line-height: 28px;
  gap: 8px;
  width: 100%;
  margin: 0;
}

.grades {
  max-width: 365px;
  margin-left: 50%;
}
.grade:first-of-type {
  padding-left: 2px;
}

.grade {
  padding-left: 48px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
}

.ratingsHeaderTitle {
  display: flex;
  align-items: center;
  padding: 16px 0 0 16px;
  font-size: 20px;
  font-weight: 600;
  color: $colorTextSecondary;
}

.ratingItem {
  line-height: 21.09px;
  margin: 16px;
  color: $colorTextSecondary;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-self: center;
  flex-wrap: nowrap;

  span {
    justify-content: start;
  }
}

.ratingDivIcon {
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;
  background-color: #e6f1f6;
  border-radius: 5px;
  height: 14px;
  box-shadow: 0px 1px 3px 0px #bdc2c6;
  width: calc(73px * 5);
}

@media (max-width: 959px) {
  .ratings {
    border-radius: 0;
  }
  .ratingDivIcon,
  .ratingDivTitle {
    margin-right: 60px;
    width: 25%;
  }
}

.ratingIcon {
  border-radius: 5px;
  height: 14px;
  width: 73px;
  background: linear-gradient(
    270deg,
    #01ada9 0%,
    rgba(1, 173, 169, 0.81) 42.52%,
    rgba(1, 173, 169, 0.61) 105.41%,
    rgba(1, 173, 169, 0) 117.65%
  );
  border-right: solid 2px #e6f1f6;
}

.ratingIcon:nth-child(1) {
  position: absolute;
  z-index: 5;
}

.ratingIcon:nth-child(2) {
  position: absolute;
  width: calc(73px * 2);
  z-index: 4;
}
.ratingIcon:nth-child(3) {
  position: absolute;
  z-index: 3;
  width: calc(73px * 3);
}
.ratingIcon:nth-child(4) {
  position: absolute;
  z-index: 2;
  width: calc(73px * 4);
}
.ratingIcon:nth-child(5) {
  position: absolute;
  z-index: 1;
  width: calc(73px * 5);
}

@media (max-width: 749px) {
  .ratings {
    display: flex;
    flex-direction: column;
    line-height: 28px;
    gap: 8px;
    width: 100%;
    margin: 0;
  }

  .grades {
    display: none;
  }

  .ratingItem {
    line-height: 21.09px;
    margin: 16px;
    color: $colorTextSecondary;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    flex-wrap: wrap;

    span {
      margin-right: auto;
      // margin-bottom: 10px;
    }
  }

  .ratingDivIcon {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    background-color: #e6f1f6;
    border-radius: 5px;
    height: 14px;
    box-shadow: 0px 1px 3px 0px #bdc2c6;
    width: calc(73px * 5);
    margin-bottom: 10px;

    margin-top: 20px;
  }
}
