$colorTextPrimary: #1b2024;
$colorTextSecondary: #485c6e;
$backgroundColorHeader: #094469;
$colorText1: #6c767e;
$borderColor: #e8e7e7;
$placeholderColor: #6c767e;
$inputBackgroundColor: #f0f0f0;
$navHoverBackgroundColor:  #f0f0f0;

$backgroundButton1: #485c6e;
$backgroundButton1Hover: #364756;

$backgroundButton2: #e5e5e5;

$backgroundColor: #f7f7f7;
$backgroundColorHover: #eeeeee;
$backgroundColorActive: #ffffff;


$colorPaper: #ffffff;
$colorPaperHover: rgba(0, 0, 0, 0.04);

$colorPrimaryMain: #01ada9;
$secondaryMainColor: #b7babd;

$backgroundColorPrimaryHeader: #e0eff3;


$backgroundButton3: #ff5d75;
$backgroundButtonHover3: #9d4144;
$backgroundButtonActive3: #ff9f9d;

$backgroundButton4: #293844;
$backgroundButtonHover4: #00121d;
$backgroundButtonActive4: #53626f;


$fontBold: Roboto-Bold, Arial, sans-serif;
$fontMedium: Roboto-Medium, Arial, sans-serif;
$fontLight: Roboto-Light, Arial, sans-serif;

