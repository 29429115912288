@import '../assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem auto 0;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 48rem;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1.5rem;
  padding: 2.5rem 3.25rem 2rem;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 28%);
  text-decoration: none;
  color: $colorTextPrimary;
  h2 {
    margin-top: 0;
    color: #00a9a8;
  }
  &:first-child {
    .btn {
      margin-top: 5rem;
    }
  }
  &:nth-child(2) {
    .btn {
      margin-top: 7rem;
    }
  }
  &:last-child {
    .btn {
      margin-top: 2.5rem;
    }
  }
}

.usingNoviopusImg,
.faqImg,
.contactImg {
  position: absolute;
  bottom: 0;
  left: 2rem;
}

.usingNoviopusImg {
  width: 61px;
}

.faqImg {
  width: 75px;
}

.contactImg {
  left: 0;
  width: 90px;
}

.link {
  align-self: flex-end;
  text-decoration: none;
}

.faqList {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  li {
    margin: 0.75rem 0;
    padding-left: 0.75rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.faqLink {
  text-decoration-color: $colorPrimaryMain;
  color: $colorPrimaryMain;
}

.form {
  margin-top: 1.5rem;
}

.btn {
  margin-top: 1.5rem;
  color: #fff;
  background-color: #007ca0;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  .btn {
    margin-right: 1rem;
  }
}

.careerLink {
  color: $colorPrimaryMain;
}

@media (min-width: 960px) {
  .careerLink {
    display: none;
  }
}