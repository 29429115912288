@import '../../assets/styles/variables';

.button {
  height: 36px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  font-family: Roboto-Medium Arial sans-serif;
  font-size: 16px;
  color: $colorTextPrimary;
  border: 1px $colorTextPrimary solid;
  margin-bottom: 7px;
  margin-right: 7px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-width: 40px;
  padding: 0 30px;

  &.checked {
    color: rgba(0, 121, 155, 1);
    background-color: white;
    border-color: rgba(0, 121, 155, 1);

    .icon svg {
      color: $colorPaper;
    }
  }
  &.checked:hover {
    color: $colorPaper;
    background-color: rgba(0, 121, 155, 1);
    border-color: rgba(0, 121, 155, 1);
    cursor: pointer;
  }

  &.unchecked {
    background-color: $colorPaper;
    &:hover {
      background-color: $colorPaperHover;
    }
  }

  & .closeButton {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $colorPaper;
    color: rgba(0, 121, 155, 1);
    right: 11px;
    // top: 11px;
  }

  &:hover .closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .text {
    font-family: Roboto-Medium, Arial, sans-serif;
    font-size: 16px;
    line-height: 14px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.uiCheckbox {
  display: none;
}

.icon {
  margin-top: 0.2rem;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

@media (max-width: 799px) {
  .button {
    width: 115px;
    .closeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
